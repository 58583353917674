import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    flatButton: {
        marginRight: 16,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginTop: 16
        }
    },
    paper: {
        margin: 20,
        width: 650,
        maxWidth: 650,
        [theme.breakpoints.down('xs')]: {
            width: 'auto'
        }
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));
