import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    header: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        padding: '26px 38px 11px 40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1000,

        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            height: 60,
            boxShadow: ({ isIFrame }) => (isIFrame ? 'none' : '0 1px 11px 0 rgba(0, 0, 0, 0.08)'),
            backgroundColor: '#ffffff',
            padding: '21px  12px 16px 19px'
        },
        '@media (max-width: 1500px)': {
            backgroundColor: '#fff'
        }
    },
    headerGrey: {
        '@media (max-width: 1500px)': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    previewLabelWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        padding: 24,
        color: '#fff',
        backgroundColor: '#3a3c43',
        textAlign: 'center',
        width: '100%',
        fontSize: 16,
        zIndex: 10
    },
    headerPreview: {
        top: 70,
        [theme.breakpoints.down('xs')]: {
            top: 92
        }
    }
}));
