import makeStyles from '@material-ui/styles/makeStyles';
import { color } from 'src/utils/mui';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'relative',
        justifyContent: 'center',
        padding: '11px 21px 12px 14px',
        textAlign: 'right',
        borderRadius: 6,
        height: 55,
        cursor: 'pointer',
        opacity: 0.5,
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            width: '100%',
            paddingLeft: 14,
            height: 50
        },
        '&:disabled': {
            opacity: 0.4
        }
    },
    wrapperHoverable: {
        '&:hover': {
            backgroundColor: 'rgba(209, 211, 217, 0.1)',
            color: '#3a3c43',
            opacity: 1,

            '& $descriptionOpened': {
                '&::after': {
                    background: 'linear-gradient(to right, rgba(255,255,255,0.001) 0%, #fafafb 100%)'
                }
            }
        }
    },
    wrapperLabel: {
        maxHeight: 32,
        color: color('textPrimary'),
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    wrapperLabelOpened: {
        alignItems: 'flex-start'
    },
    wrapperActive: {
        color: '#3a3c43',
        opacity: '1 !important',

        '& $descriptionOpened': {
            '&::after': {
                background: 'linear-gradient(to right, rgba(255,255,255,0.001) 0%, #fafafb 100%)'
            }
        }
    },
    wrapperActiveBackground: {
        backgroundColor: 'rgba(209, 211, 217, 0.1)'
    },
    description: {
        fontWeight: 'normal',
        fontSize: 11,
        lineHeight: 1.45,
        overflowHidden: 'hidden',
        transition: 'all 0.3s',
        textAlign: 'left',
        width: 0,
        opacity: 0
    },
    descriptionOpened: {
        width: 200,
        opacity: 1,
        overflow: 'hidden',
        maxHeight: 30,
        marginLeft: 18,

        '&::after': {
            content: '""',
            zIndex: 1,
            width: '30%',
            height: '30%',
            position: 'absolute',
            right: 17,
            bottom: 12,
            background: 'linear-gradient(to right, rgba(255,255,255,0.001) 0%, rgba(255, 255, 255, 1) 100%)'
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            fontSize: 12
        }
    },
    number: {
        fontSize: 16,
        lineHeight: 1,
        fontWeight: 700,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            lineHeight: 1.3
        }
    },
    currentQuestionStyle: {
        position: 'absolute',
        zIndex: 2,
        top: 0,
        bottom: 0,
        right: 6,
        marginTop: 'auto',
        marginBottom: 'auto',
        width: 4,
        height: 40,
        borderRadius: '2px',
        backgroundColor: color('themeColor')
    },
    completedQuestion: {
        position: 'absolute',
        zIndex: 2,
        top: 0,
        bottom: 0,
        right: 2,
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    additionalInfo: {
        position: 'absolute',
        zIndex: 2,
        right: 16,
        bottom: 6,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    timerIcon: {
        marginLeft: 4
    },
    requiredLabel: {
        height: 15.4,
        fontSize: 6.5,
        borderRadius: 7.8,
        padding: '3px 7px',
        marginTop: 1
    },
    flatButton: {
        paddingLeft: 25,
        paddingRight: 25,
        height: 40,
        marginRight: 16
    },
    flatButtonLabel: {
        color: '#676a78',
        letterSpacing: '0.2px'
    },
    touchRippleRoot: {
        zIndex: 2
    }
}));
