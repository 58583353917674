import React, { useState } from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';

export const Tooltip = ({ label, children, placement = 'top', classes, light, ...other }) => {
    const [arrowRef, setArrowRef] = useState(null);
    const {
        arrowPopper,
        tooltipLight,
        arrowPopperLight,
        arrow,
        tooltip
    } = useStyles({});

    return (
        <MaterialTooltip
            title={(
                <React.Fragment>
                    { label }
                    <span className={arrow} ref={(node) => { setArrowRef(node); }} />
                </React.Fragment>
            )}
            placement={placement}
            classes={{
                tooltip: light ? tooltipLight : tooltip,
                popper: light ? arrowPopperLight : arrowPopper,
                ...classes
            }}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef
                        }
                    }
                }
            }}
            {...other}
        >
            { children }
        </MaterialTooltip>
    );
};
