import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { CenteredContent } from 'src/components/center_content';
import { buildVariant } from 'src/utils/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    title: {
        ...buildVariant('bold', 24, 'textPrimary'),
        lineHeight: 1.25,
        maxWidth: 600,
        textAlign: 'center'
    },
    wrapper: {
        paddingTop: 222,
        width: 600,
        position: 'absolute',
        transform: 'translateY(-55%)',
        top: '40%'
    }
});

export default () => {
    const { wrapper, title } = useStyles();
    const { t } = useTranslation();
    return (
        <CenteredContent>
            <div className={wrapper}>
                <CenteredContent>
                    <Typography classes={{ root: title }} variant="h2">
                        {t('Seems like you’re experiencing some issues with your network provider. Please reload the page.')}
                    </Typography>
                </CenteredContent>
            </div>
        </CenteredContent>
    );
};
