import React, { useContext } from 'react';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { useTranslation } from 'react-i18next';
import { useLink } from '../../../../utils/routing';
import { AuthStoreCtx } from '../../../../store/auth';
import iconHelp from '../../../../assets/icons/help.svg';

import clearIcon from './close.svg';
import ListItem from './components/ListItem';
import { useStyles } from './styles';


const MenuMobileSideBar = ({ close, open }) => {
    const {
        list, headOfSideBar,
        listComponent, textStyles,
        containerStyle
    } = useStyles({});
    const { logOut } = useContext(AuthStoreCtx);
    const { t } = useTranslation();

    return (
        <div>
            <Drawer
                anchor="right"
                open={open}
                onClose={close}
                BackdropProps={{ classes: { root: containerStyle } }}
                classes={{ modal: containerStyle }}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onClick={close}
                    onKeyDown={close}
                >
                    <div className={list}>
                        <div className={headOfSideBar}>
                            <img src={clearIcon} alt=""/>
                        </div>

                        <List classes={{ root: listComponent }}>
                            <Divider/>
                            <a
                                href="https://vervoe.zendesk.com/hc/en-us/categories/360001807852-Candidate-Support"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ListItem
                                    primary={t('Help')}
                                    src={iconHelp}
                                />
                            </a>
                            <Divider/>
                        </List>
                        <List>
                            <ListItem
                                textStyles={textStyles}
                                onClick={useLink('change_email', {})}
                                primary={t('Change email')}
                            />
                            <a target="_blank" rel="noopener noreferrer" href="https://vervoe.com/terms-of-use">
                                <ListItem
                                    textStyles={textStyles}
                                    onClick={useLink('profile', {})}
                                    primary={t('Terms and privacy')}
                                />
                            </a>
                            <ListItem
                                textStyles={textStyles}
                                onClick={logOut}
                                primary={t('Logout')}
                            />
                        </List>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default MenuMobileSideBar;
