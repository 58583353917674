import React from 'react';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useStyles } from './styles';

export const CustomDialog = ({
    title, handleClose,
    hideCloseIcon, content,
    actions, open, ...other
}) => {
    const {
        dialogTitle, dialogContent,
        closeIcon, dialogActions
    } = useStyles({});

    return (
        <Dialog
            onClose={handleClose}
            open={Boolean(open)}
            {...other}
        >
            <DialogTitle
                classes={{ root: dialogTitle }}
                disableTypography
            >
                {title}
                {
                    !hideCloseIcon
                    && <Close onClick={handleClose} classes={{ root: closeIcon }}/>
                }
            </DialogTitle>
            <DialogContent classes={{ root: dialogContent }}>
                {content}
            </DialogContent>
            {
                actions && (
                    <DialogActions classes={{ root: dialogActions }} >
                        { actions }
                    </DialogActions>
                )
            }
        </Dialog>
    );
};
