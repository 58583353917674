import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
    wrapper: {
        marginLeft: '15px',
        padding: '5px 10px',
        backgroundColor: '#e6e6e6',
        borderRadius: '12px',
        fontSize: '10px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        letterSpacing: '0.8px',
        textAlign: 'center',
        color: '#1a1b1e'
    }
});
