import React, { useContext } from 'react';
import { tz } from 'moment-timezone';
import { observer } from 'mobx-react-lite';

import Button from '@material-ui/core/Button';
import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { useWidth } from 'src/utils/mui';

import { secondsToTimeObject, makePluralString } from 'src/utils/helperFunctions';
import { CustomDialog } from 'src/components/custom_dialog';

import { Trans, useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const CompleteLaterDialog = observer(({ open, handleOpen }) => {
    const { flatButton, paper, noWrap } = useStyles({});
    const { isLess } = useWidth('xs');
    const { interview, timerValue, completeLaterFunction } = useContext(InterviewPageStoreCtx);
    const { t, i18n } = useTranslation();
    if (!interview) return null;

    const { timerEnabled, expirationDate, expirationTimeStatus } = interview;
    const isES = i18n.language === 'es';
    const submitBy = isES
        ? tz(expirationDate, tz.guess()).format('[el] D [de] MMMM [de] YYYY [a las] h:mma z')
        : tz(expirationDate, tz.guess()).format('MMMM Do YYYY [at] h:mma z');

    const getTimerLabel = () => {
        const { days, hours, minutes, seconds } = secondsToTimeObject(timerValue);
        const getStrForValue = (value, str) => {
            return value ? `${value} ${makePluralString(value, str)}` : '';
        };
        const strDay = getStrForValue(days, t('day'));
        const strHours = getStrForValue(hours, t('hour'));
        const strMin = getStrForValue(minutes, t('minute'));
        const strSec = getStrForValue(seconds, t('second'));

        if (days) {
            return `${strDay} ${strHours}`;
        }

        if (hours) {
            return `${strHours} ${strMin}`;
        }

        if (minutes) {
            return `${strMin} ${strSec}`;
        }

        return strSec;
    };

    let dialogContentLabel = expirationTimeStatus ? (
        <Trans i18nKey="deadline">Your deadline to submit this assessment is <strong className={noWrap}>{{ submitBy }}</strong>.<br /></Trans>
    ) : (
        <Trans i18nKey="noDeadline">
            This assessment doesn't have a deadline.
            Candidates who complete the assessment within two days tend to have significantly higher chances of employment.<br />
        </Trans>
    );

    if (timerEnabled && timerValue) {
        dialogContentLabel = (
            <Trans i18nKey="timerText">
                You have <strong className={noWrap}>{{ time: getTimerLabel() }}</strong> left to complete this assessment.<br/>
                The <strong>timer will not pause</strong> even if you choose to complete later.<br/>
            </Trans>
        );
    } else if (timerValue) {
        dialogContentLabel = (
            <>
                <Trans i18nKey="timedQuestionsInfo">
                    You have one or more timed questions that have been started.<br />
                    <strong>The timers will not pause</strong> even if you choose to complete the assessment later.<br />
                    You will not be able to complete these questions if you run out of time.<br /><br />
                </Trans>
            </>
        );
    }

    return (
        <CustomDialog
            open={open}
            handleClose={handleOpen}
            title={t('Complete later?')}
            classes={{ paper }}
            content={(
                <>
                    { dialogContentLabel }
                    {t('All of your previous answers have been saved.')}
                </>
            )}
            actions={(
                <>
                    <Button
                        onClick={completeLaterFunction}
                        classes={{ root: flatButton }}
                        fullWidth={isLess}
                    >
                        {t('Complete later')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        fullWidth={isLess}
                    >
                        {t('Continue')}
                    </Button>
                </>
            )}
        />
    );
});
