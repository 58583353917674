import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@material-ui/styles/makeStyles';

import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useTranslation } from 'react-i18next';
import { AuthStoreCtx } from '../../../../store/auth';
import { useLink } from '../../../../utils/routing';
import { useWidth } from '../../../../utils/mui';

import { HelpIcon } from './help_icon';
import UserIcon from './userIcon.svg';

const useStyles = makeStyles(theme => ({
    handler: {
        cursor: 'pointer'
    },
    popover: {
        minWidth: 189,
        height: 170,
        borderRadius: 4,
        boxShadow: '0 2px 15px 0 rgba(205, 205, 205, 0.31)',
        overflow: 'hidden'
    },
    list: {
        paddingLeft: 20
    },
    textMenuSecond: {
        fontWeight: 500,
        fontSize: 13,
        color: '#676a78'
    },
    textMenuFirst: {
        fontWeight: 600,
        fontSize: 14,
        color: '#676a78'
    },
    avatar: {
        marginLeft: 'auto',
        width: 40,
        height: 40,

        [theme.breakpoints.down('xs')]: {
            height: 28,
            width: 28
        }
    }
}));

export const MenuPopover = observer(({ mobileMenu, onClick }) => {
    const [modal, handleModal] = useState(null);
    const { handler, popover, avatar, textMenuFirst, textMenuSecond } = useStyles({});
    const { logOut } = useContext(AuthStoreCtx);
    const goToChangeEmail = useLink('change_email', {});

    const { isLess } = useWidth('xs');

    const { t } = useTranslation();

    function openModal(e) {
        handleModal(e.currentTarget);
    }

    return (
        <div>
            <div
                className={handler}
                aria-owns="simple-popper"
                aria-haspopup="true"
                variant="contained"
                onClick={isLess ? mobileMenu : openModal}
            >
                <img
                    onClick={onClick}
                    src={UserIcon}
                    alt="menu"
                    className={avatar}
                />
            </div>
            <Popover
                id="simple-popper"
                open={Boolean(modal)}
                classes={{ paper: popover }}
                anchorEl={modal}
                onClose={() => handleModal(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <List component="nav">
                    <a target="_blank" rel="noopener noreferrer" href="https://vervoe.zendesk.com/hc/en-us/categories/360001807852-Candidate-Support">
                        <ListItem button>
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: textMenuFirst }} primary={t('Help')} />
                        </ListItem>
                    </a>
                    <ListItem onClick={goToChangeEmail} button>
                        <ListItemText classes={{ primary: textMenuSecond }} primary={t('Change email')} />
                    </ListItem>
                    <ListItem button>
                        <a target="_blank" rel="noopener noreferrer" href="https://vervoe.com/terms-of-use">
                            <ListItemText classes={{ primary: textMenuSecond }} primary={t('Terms and privacy')} />
                        </a>
                    </ListItem>
                    <ListItem onClick={logOut} button>
                        <ListItemText classes={{ primary: textMenuSecond }} primary={t('Logout')} />
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
});
