import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { CustomDialog } from 'src/components/custom_dialog';

import { TextField } from '../text_field';
import { useStyles } from './styles';

import { interviewApi } from '../../api/interview';

export const CSATDialog = observer(({ open }) => {
    const classes = useStyles({});
    const { t } = useTranslation();
    const { redirectAfterSubmit, interview, submitURL } = useContext(InterviewPageStoreCtx);
    const [submitProcess, setSubmitProcess] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [reason, setReason] = useState('');
    const [loadingSkip, setLoadingSkip] = useState(false);

    const availableGrades = [1, 2, 3, 4, 5];

    const gradeAnswer = (e, item) => {
        e.stopPropagation();
        setSelectedGrade(item);
    };

    const handleSubmitInterview = async () => {
        const { id } = interview;
        if (!selectedGrade) return;
        setSubmitProcess(true);
        try {
            await interviewApi.setCandidateFeedback(id, selectedGrade, reason);
            redirectAfterSubmit();
        } catch (e) {
            setSubmitProcess(false);
        }
    };

    const onChangeReason = (e) => {
        const { value } = e.target;
        setReason(value);
    };

    const handleClose = () => {
        const { id } = interview;
        if (!submitProcess && !loadingSkip) interviewApi.setCSATSeen(id);
        if (submitURL && !submitProcess) setLoadingSkip(true);
        redirectAfterSubmit();
    };

    const csatDialogActionButtons = [
        <Button
            color="primary"
            className={classes.buttonStyle}
            onClick={handleClose}
            key={1}
            classes={{ root: classes.flatButton }}
            disabled={submitProcess || loadingSkip}
        >
            <Trans>Skip</Trans>
            {loadingSkip && <CircularProgress size={18} className={classes.circularProgressSkip} />}
        </Button>,
        <Button
            onClick={handleSubmitInterview}
            key={0}
            variant="contained"
            color="primary"
            disabled={submitProcess || loadingSkip || !selectedGrade}
            className={classes.buttonStyle}
        >
            {
                submitProcess ? (
                    <>
                        <span className={classes.submitting}>{t('Submitting...')}</span>
                        <CircularProgress size={18} className={classes.circularProgress} />
                    </>
                ) : (
                    <Trans>Submit Survey</Trans>
                )
            }
        </Button>
    ];

    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            classes={{ paper: classes.dialogPaper }}
            title={<div className={classes.titleStyle}><Trans>Before you go</Trans></div>}
            content={(
                <>
                    <div><Trans>How satisfied are you with the assessment experience?</Trans></div>
                    <div className={classes.wrapper}>
                        <div className={classes.gradeWrapper}>
                            <div className={clsx(classes.gradeRate, classes.gradeRate)}>
                                {t('Not')}<br />{t('satisfied')}
                            </div>
                            {availableGrades
                                .map((item) => {
                                    const isActive = selectedGrade !== null && parseInt(selectedGrade, 10) === item;
                                    return (
                                        <div
                                            role="presentation"
                                            onClick={(submitProcess || loadingSkip) ? () => {} : e => gradeAnswer(e, item)}
                                            className={clsx(classes.grade, isActive && classes.gradeSelected, (submitProcess || loadingSkip) && classes.gradeDisabled)}
                                            key={item}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            <div className={classes.gradeRate}>
                                {t('Completely')}<br />{t('satisfied')}
                            </div>
                        </div>
                    </div>

                    {
                        selectedGrade && (
                            <>
                                <Trans>Could you tell us why you gave this score?</Trans>

                                <TextField
                                    label={t('Why did you give your score?')}
                                    value={reason}
                                    fullWidth
                                    maxLength={500}
                                    multiline
                                    hasCounter
                                    disabled={submitProcess || loadingSkip}
                                    className={classes.reasonInput}
                                    InputProps={{
                                        classes: {
                                            root: classes.reasonInputInput
                                        }
                                    }}
                                    onChange={onChangeReason}
                                />
                            </>
                        )
                    }
                </>
            )}
            actions={csatDialogActionButtons}
        />
    );
});
