import makeStyles from '@material-ui/styles/makeStyles';
import { buildVariant } from 'src/utils/mui';

export const useStyles = makeStyles(theme => ({
    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 15,
        top: 0,
        bottom: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        ...buildVariant(600, 30, 'textEmphasis')
    },
    dialogTitle: {
        position: 'relative',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        paddingRight: 50,
        ...buildVariant('bold', 24),
    },
    dialogContent: {
        lineHeight: 1.5,
        letterSpacing: 'normal',
        ...buildVariant('normal', 16),
        color: '#4a4a4a'
    },
    dialogActions: {
        width: '100%',
        margin: 0,
        backgroundColor: 'rgba(243, 243, 243, 0.74)',
        padding: '15px 20px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse'
        }
    }
}));
