import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles({
    wrapper: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20
    },
    buttonsWrapper: {
        textAlign: 'right'
    }
});
