import React from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MUISelect from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    menuItemRoot: {
        whiteSpace: 'break-spaces',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12
        }
    },
    selectPaper: {
        maxWidth: 500
    }
});

class Select extends React.Component {
    state = {
        labelWidth: 0
    };

    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
        });
    }

    render() {
        const {
            value, handleChange, options,
            label, className, name, classes,
            helperText, error, helperTextClassName
        } = this.props;
        const { labelWidth } = this.state;

        return (
            <FormControl variant="outlined" className={className}>
                <InputLabel
                    ref={(ref) => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-simple"
                >
                    <Trans>{label}</Trans>
                </InputLabel>
                <MUISelect
                    value={value}
                    onChange={handleChange}
                    MenuProps={{ classes: { paper: classes.selectPaper }}}
                    input={(
                        <OutlinedInput
                            labelWidth={labelWidth}
                            name={name}
                            id="outlined-simple"
                        />
                    )}
                >
                    {options.map(option => (
                        <MenuItem
                            key={option}
                            classes={{ root: classes.menuItemRoot }}
                            value={option}
                        >
                            <Trans>{option}</Trans>
                        </MenuItem>
                    ))}
                </MUISelect>
                {helperText && (
                    <FormHelperText error={error} className={helperTextClassName}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(styles)(Select);
