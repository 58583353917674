import makeStyles from '@material-ui/styles/makeStyles';
import { buildVariant } from '../../../../utils/mui';

export const useStyles = makeStyles(theme => ({
    logoCorp: {
        height: 40,
        objectFit: 'contain',
        cursor: 'pointer',

        [theme.breakpoints.down('xs')]: {
            height: 21
        }
    },
    logoCorpDisabled: {
        cursor: 'default'
    },
    logoText: {
        maxWidth: '45%',
        marginTop: 5,
        fontFamily: 'Proximanova-Extrabold',
        ...buildVariant(400, 25, 'logo')
    }
}));
