import { createContext } from 'react';
import axios from 'axios';
import { fromPromise } from 'mobx-utils';
import { observable, action, computed, when } from 'mobx';
import { generateLanguagesArray } from 'src/utils/helperFunctions';
import { colors } from 'src/utils/mui';
import { interviewApi } from '../../api/interview';
import { WrapperRequest } from '../../api/wrapperRequest';
import {
    CODE_TEST_AUTOGRADED, TYPING_TEST, CS_SIMULATION,
    ANY_FILE, MULTIPLE_CHOICE, NETWORK_SIMULATION,
    NO_ANSWER, FEEDBACK, SALES_SIMULATION, QA_SIMULATION,
    MARKETING_SIMULATION
} from '../../utils/constants';

import { routerStore } from '../../store/router';
import { ScreeningContentStore } from './screeningStore';

const { CancelToken } = axios;

class InterviewPage {
    @observable.ref questionReq = null;

    @observable interview = null;

    @observable questions = [];

    @observable currentQuestionIndex = null;

    @observable answerValue = null;

    @observable seenQuestionsArray = [];

    @observable timeSpentOnCurrentQuestion = 0;

    @observable timerValue = null;

    @observable loadingItems = [];

    @observable cancelUploadDialogData = null;

    @observable codeChallenge = [];

    @observable languages = [];

    @observable activeLanguage = null;

    @observable activeCodeEditor = { header: '', body: '', footer: '' };

    @observable cases = [];

    @observable feelingChangingCode = null;

    @observable isAnswerSaving = false;

    @observable saveError = null;

    @observable isShownCheckmark = false;

    @observable codeChallengeQuestions = null;

    @observable csSimulatorTicketsResults = null;

    @observable isExpiredDialogOpened = false;

    @observable deadlineExpiredDialogOpened = false;

    @observable lastTimerTick = 0;

    @observable submitURL = '';

    @observable ibmRedirectUrl = '';

    @observable simulatorOpen = false;

    @observable currentWordIndex = 0;

    @observable typingTimerCurrent = 0;

    @observable typingTestId = null;

    @observable isAvailableTypingTest = true;

    @observable typingTestBestAttemptUUID = null;

    @observable assessmentBranding = null;

    @observable linuxSimulationHostsCreated = null;

    typingTimer = null;

    source = null;

    timerId = null;

    timedIdForAnswerSavingDelay = null;

    timerInterviewId = null;

    statisticsTimerId = null;

    savedIntermediateResultTimer = null;

    checkmarkTimer = null;

    @computed get currentQuestion() {
        return this.questions[this.currentQuestionIndex - 1];
    }

    @computed get prevQuestion() {
        if (!this.currentQuestionIndex) { return null; }
        return this.questions[this.currentQuestionIndex - 2];
    }

    @computed get nextQuestion() {
        if (!this.currentQuestionIndex) { return null; }
        return this.questions[this.currentQuestionIndex];
    }

    @computed get hasAnswerQuestion() {
        if (!this.currentQuestionIndex) { return null; }
        return this.questions[this.currentQuestionIndex - 1].questionHasAnswer;
    }

    @computed get leftTypingTestTime() {
        return this.currentQuestion.question.typingTestTimerLimit - this.typingTimerCurrent;
    }

    @action onInterviewExpired = () => {
        this.saveStatisticsTimerForPreviousQuestion();
        this.interview.expired = true;
        this.setDeadlineExpiredDialogOpened(true);
    };

    @action setAssessmentBranding = (brandObj) => {
        this.assessmentBranding = brandObj;
    }

    @action setBestAttemptUUID = (uuid) => {
        this.typingTestBestAttemptUUID = uuid;
    }

    @action setIsAvailableTypingTest = (value) => {
        this.isAvailableTypingTest = value;
    }

    @action decreaseCurrentWordIndex = () => {
        this.currentWordIndex = this.currentWordIndex - 1;
    }

    @action increaseCurrentWordIndex = () => {
        this.currentWordIndex = this.currentWordIndex + 1;
    }

    @action resetTypingTimer = () => {
        this.typingTimerCurrent = 0;
    }

    @action startTypingTest = (uuid) => {
        this.typingTestId = uuid;
        this.typingTimerCurrent = 1;
        this.isAvailableTypingTest = true;
        this.currentWordIndex = 0;
        this.typingTimer = setInterval(() => {
            this.typingTimerCurrent = this.typingTimerCurrent + 1;

            if (this.leftTypingTestTime < 0) {
                this.typingTestId = null;
                this.typingTimerCurrent = 0;
                this.isAvailableTypingTest = true;
                this.currentWordIndex = 0;

                if (this.questions[this.currentQuestionIndex - 1].type === TYPING_TEST) {
                    this.changeQuestion(this.currentQuestionIndex);
                }

                clearInterval(this.typingTimer);
            }
        }, 1000);
    };

    @action completeTypingTest = async () => {
        const typingId = this.typingTestBestAttemptUUID || this.typingTestId;
        await interviewApi.completeTypingTest(typingId);
        clearInterval(this.typingTimer);
        this.typingTestId = null;
        this.typingTimerCurrent = 0;
        this.currentWordIndex = 0;
        window.onkeydown = null;
        this.changeQuestion(this.currentQuestionIndex);
    };

    @action setExpiredDialogOpened = (value) => {
        this.isExpiredDialogOpened = value;
    }

    @action setDeadlineExpiredDialogOpened = (value) => {
        this.deadlineExpiredDialogOpened = value;
    }

    @action openSimulatorDialog = (question) => {
        if (question) {
            const { started, timedQuestion, id } = question;
            if (!started && timedQuestion) {
                this.startTimedQuestion(id, this.currentQuestionIndex);
            } else {
                this.setLinuxQuestionSeen(question);
            }
        }
        this.simulatorOpen = true;
    }

    @action setLinuxQuestionSeen = (question) => {
        const { id, type } = question;
        if (type === NETWORK_SIMULATION) {
            this.questionReq = this.getQuestion(this.currentQuestionIndex);
            interviewApi.setQuestionNetworkAsSeen(id);
            question.questionHasAnswer = true;
            this.questions[this.currentQuestionIndex - 1].questionHasAnswer = true;
        }
    }

    @action closeSimulatorDialog = () => {
        const { type } = this.currentQuestion;
        if ([SALES_SIMULATION, QA_SIMULATION, MARKETING_SIMULATION].includes(type)) {
            this.questionReq = this.getQuestion(this.currentQuestionIndex);
        }
        this.simulatorOpen = false;
    }

    @action resetCodeChallengeParams = () => {
        this.answerValue = this.codeChallengeQuestions;
        this.languages = generateLanguagesArray(this.codeChallengeQuestions);

        this.activeCodeEditor = this.codeChallengeQuestions.reduce((value, { language, body, header, footer }) => {
            if (language === this.activeLanguage.toLowerCase()) {
                value = { body, header, footer };
            }
            return value;
        }, {});
    };

    @action initCodeChallenageParams = (codeChallenge) => {
        let currentCode = null;
        if (this.codeChallengeResults) {
            currentCode = codeChallenge.map((item) => {
                const { code } = this.codeChallengeResults.find(({ language }) => language === item.language);
                item.body = code || item.body;
                return item;
            });
        } else {
            currentCode = codeChallenge;
        }

        this.answerValue = currentCode;
        this.languages = generateLanguagesArray(currentCode);
        const { header, body, footer } = currentCode[0];
        this.activeLanguage = currentCode[0].language;
        this.activeCodeEditor = { header, body, footer };
        if (this.codeChallengeResults) {
            Promise.all(this.codeChallengeResults.map(({ id }, index) => interviewApi.saveIntermediateResult(
                this.currentQuestion.id,
                id,
                { code: currentCode[index].body }
            )));
        }
    };

    @action changeCodeEditorValue = (codeEditor) => {
        this.feelingChangingCode = 'Typing...';
        clearTimeout(this.savedIntermediateResultTimer);
        this.savedIntermediateResultTimer = setTimeout(() => {
            const { id } = this.codeChallengeResults.find(({ language }) => (
                language === this.activeLanguage.toLowerCase()));
            const data = { code: codeEditor.body };
            interviewApi.saveIntermediateResult(this.currentQuestion.id, id, data)
                .then(({ success }) => {
                    if (success) {
                        this.feelingChangingCode = 'All changes saved';
                    }
                });
        }, 300);

        this.activeCodeEditor.body = codeEditor.body;
    };

    @action changeActiveLanguage = ({ name }) => {
        const newCodeChallenge = [...this.answerValue];
        const updatedChallenge = newCodeChallenge.map((item) => {
            if (this.activeLanguage.toLowerCase() === item.language) {
                const { header, body, footer } = this.activeCodeEditor;
                item = { language: item.language, header, body, footer };
            }
            return item;
        });

        const newIndex = updatedChallenge.findIndex(({ language }) => language === name.toLowerCase());


        const { header, body, footer } = updatedChallenge[newIndex];

        this.activeCodeEditor = { header, body, footer };
        this.answerValue = updatedChallenge;
        this.activeLanguage = name;
    };

    @action getTestCases = (id) => {
        interviewApi.getTestCases(id)
            .then(({ data }) => {
                this.cases = data;
            });
    };

    @action generatePassedTestCase = (data) => {
        this.cases = data;
    };

    @action setInterview = (newInterview) => {
        this.interview = newInterview;
        if (newInterview.brand) {
            this.assessmentBranding = newInterview.brand;
            colors.themeColor = newInterview.brand.color;
        }
        if (newInterview.timerEnabled && !newInterview.screeningStatus) {
            this.setTimerForTimedInterview(newInterview.timeRemaining);
        }
    };

    @action getQuestions = async (id = this.interview?.id, questionId) => {
        const questionsResult = await interviewApi.getInterviewQuestions(id);
        if (questionsResult.success) {
            this.setQuestions(questionsResult.data, questionId);
        }
    };

    @action getInterview = async (interviewId = this.interview?.id, questionId, onError) => {
        try {
            const interviewResult = await interviewApi.startInterview(interviewId);
            if (interviewResult.success) {
                const startedInterview = interviewResult.data;
                if (startedInterview.brand) {
                    this.setAssessmentBranding(startedInterview.brand);
                }
                if (startedInterview.expired) {
                    routerStore.goTo({ name: 'index' });
                }

                this.setInterview(startedInterview);

                // Screening
                if (startedInterview.screeningStatus === 'screeningInProgress') {
                    ScreeningContentStore.setScreeningStatus(startedInterview.screeningStatus);
                    ScreeningContentStore.getQuestions(interviewId);
                    return;
                } if (startedInterview.screeningStatus === 'screeningFailed') {
                    ScreeningContentStore.setScreeningStatus(startedInterview.screeningStatus);
                    return;
                }
                this.getQuestions(interviewId, questionId);
            }
        } catch (e) {
            if (onError) onError();
        }
    }

    @action setQuestions = (newQuestions, id) => {
        this.questions = newQuestions;
        if (!this.questions || !this.questions.length) return;
        let questionIndex = 0;
        if (id) questionIndex = this.questions.findIndex(({ id: questionId }) => questionId.toString() === id);
        if (questionIndex === -1) questionIndex = 0;
        this.changeQuestion(questionIndex + 1);
    };

    @action getQuestion = (index) => {
        const { id } = this.questions[index - 1];
        this.saveStatisticsTimerForPreviousQuestion();
        return fromPromise(interviewApi.getInterviewQuestion(id)
            .then((response) => {
                const { data } = response;
                const {
                    question, codeChallengeQuestionResults, id: dataId,
                    type, csSimulatorTicketsResults, linuxSimulationHostsCreated, questionHasAnswer
                } = data;
                this.csSimulatorTicketsResults = csSimulatorTicketsResults;
                this.linuxSimulationHostsCreated = linuxSimulationHostsCreated;
                this.questions[index - 1].questionHasAnswer = questionHasAnswer;

                if (type === CODE_TEST_AUTOGRADED && question.codeChallengeQuestions && question.codeChallengeQuestions.length) {
                    this.codeChallengeResults = codeChallengeQuestionResults;
                    this.codeChallengeQuestions = question.codeChallengeQuestions;
                    this.initCodeChallenageParams(question.codeChallengeQuestions);
                    this.getTestCases(dataId);
                }
                this.setStatisticsTimerAndSeenOnTimeOut();
                this.setTimerForTimedQuestion(index, data);
                return response;
            })
            .catch((error) => {
                if (error.response && error.response.status === 410) {
                    this.onInterviewExpired();
                }
                return error;
            }));
    }

    @action changeQuestion = async (index) => {
        const { id } = this.questions[index - 1];
        if (this.handleCancelUploadDialog(index)) return;

        routerStore.goTo({ name: 'interview', params: { id } });

        clearTimeout(this.timerId);
        clearTimeout(this.statisticsTimerId);

        this.saveStatisticsTimerForPreviousQuestion();
        this.showCheckmark();
        this.currentQuestionIndex = index;
        this.answerValue = null;
        this.saveError = '';
        this.simulatorOpen = false;
        this.scrollStepperToNextQuestion();

        this.questionReq = this.getQuestion(index);
    };

    @action showCheckmark = () => {
        this.isShownCheckmark = false;
        clearTimeout(this.checkmarkTimer);
        if (!this.answerValue) return;
        this.isShownCheckmark = true;
        this.checkmarkTimer = setTimeout(() => {
            this.isShownCheckmark = false;
        }, 5000);
    };

    scrollStepperToNextQuestion = () => {
        const prevQuestion = this.questions[this.currentQuestionIndex - 2];
        if (!prevQuestion) return;
        const { id } = prevQuestion;
        const element = document.getElementById(`step_${id}`);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    @action handleCancelUploadDialog = (nextIndex) => {
        if (this.currentQuestion && this.loadingItems.indexOf(this.currentQuestion.id) > -1) {
            if (!this.cancelUploadDialogData) {
                this.cancelUploadDialogData = nextIndex;
                return true;
            }
            this.closeCancelUploadDialog();
            this.removeItemFromLoadingArray(this.currentQuestion.id);
        }
        return false;
    };

    @action saveFileAnswer = async (callback, data) => {
        const { id } = this.currentQuestion;
        try {
            return await interviewApi.uploadFileAnswer(id, data, { onUploadProgress: callback, cancelToken: this.source.token });
        } catch (err) {
            if (err.response && err.response.data) this.saveError = err.response.data.errors;
            this.removeItemFromLoadingArray(id);
        }
    };

    @action saveAnswer = async (callback) => {
        if (this.answerValue === null || !this.currentQuestion) return;
        const { id, question } = this.currentQuestion;

        let questionHasAnswer = Boolean(this.answerValue);
        if (question.answerType === MULTIPLE_CHOICE) {
            questionHasAnswer = this.answerValue.some(item => item.checked);
        }

        const questionIndex = this.currentQuestionIndex;

        try {
            let response;
            if (question && question.answerType === ANY_FILE) {
                await WrapperRequest.refreshToken();
                this.source = CancelToken.source();
                const data = new FormData();
                data.append('file', this.answerValue);
                await interviewApi.startUploading(id);
                response = await this.saveFileAnswer(callback, data);
            } else if (question && question.answerType === CODE_TEST_AUTOGRADED) {
                const { id: idLang } = this.codeChallengeResults.find(({ language }) => this.activeLanguage.toLowerCase() === language);
                response = interviewApi.saveAnswer(id, {
                    answer: idLang
                });
            } else if (question && question.answerType === CS_SIMULATION) {
                response = await interviewApi.saveSimulatorAnswer(id, this.answerValue);
                const questionResponse = await interviewApi.getInterviewQuestion(id);
                this.csSimulatorTicketsResults = questionResponse.data.csSimulatorTicketsResults;
                questionHasAnswer = this.csSimulatorTicketsResults.every(item => item.hasCheckedAnswer);
                if (questionHasAnswer) this.closeSimulatorDialog();
                if (callback) callback();
            } else if (question && question.answerType === TYPING_TEST) {
                response = await interviewApi.saveAnswer(id, {
                    answer: this.answerValue,
                    time: this.typingTimerCurrent,
                    uuid: this.typingTestId
                });
                if (callback) callback();
            } else {
                response = await interviewApi.saveAnswer(id, { answer: this.answerValue });
            }
            if (response.success) {
                this.removeItemFromLoadingArray(id);
                this.closeCancelUploadDialog();
                this.saveError = '';

                this.questions[questionIndex - 1].questionHasAnswer = questionHasAnswer;
            }
            return response;
        } catch (e) {
            if (e.response && e.response.data) {
                this.saveError = e.response.data.errors;
            }
            if (e.response && e.response.status === 410) {
                this.onInterviewExpired();
            }
        }
    };

    @action addItemToLoadingArray = (loadingQuestionId) => {
        this.loadingItems.push(loadingQuestionId);
    };

    @action removeItemFromLoadingArray = (loadingQuestionId) => {
        const index = this.loadingItems.indexOf(loadingQuestionId);

        if (index > -1) {
            this.loadingItems.splice(index, 1);
        }
    };

    @action cleanStore = () => {
        this.saveStatisticsTimerForPreviousQuestion();
        this.currentQuestionIndex = null;
        this.questions = [];
        this.questionReq = null;
        this.isExpiredDialogOpened = false;
        this.simulatorOpen = false;
        this.submitURL = '';
        clearInterval(this.timerId);
        clearInterval(this.timerInterviewId);
        clearInterval(this.statisticsTimerId);
    };

    @action onChangeAnswer = (newAnswer, callback) => {
        if (this.answerValue === newAnswer) return;
        clearTimeout(this.timedIdForAnswerSavingDelay);
        this.saveError = '';
        this.answerValue = newAnswer;
        this.timedIdForAnswerSavingDelay = setTimeout(() => {
            this.saveAnswer(callback);
        }, 300);
    };

    @action goToNextQuestion = () => {
        this.changeQuestion(this.currentQuestionIndex + 1);
    };

    @action goToPreviousQuestion = () => {
        this.changeQuestion(this.currentQuestionIndex - 1);
    };

    /* Statistics Timer for each questions */
    @action setStatisticsTimerAndSeenOnTimeOut = () => {
        if (!this.currentQuestion) return;
        clearTimeout(this.statisticsTimerId);
        this.timeSpentOnCurrentQuestion = 0;

        if (!this.checkIfQuestionWasSeen()) return;

        this.setQuestionAsSeen();
        this.setTimeSpentOnCurrentQuestion();
    };

    @action checkIfQuestionWasSeen = () => {
        if (!this.currentQuestion) return false;

        const isQuestionHidden = !this.currentQuestion.started && this.currentQuestion.timedQuestion;
        const isQuestionExpired = this.currentQuestion.timedQuestion && this.currentQuestion.remainingTime <= 0;
        const isAuditionExpired = (!this.timerValue && this.interview.timerEnabled) || this.interview.expired;
        return !isQuestionHidden && !isQuestionExpired && !isAuditionExpired;
    }

    @action setQuestionAsSeen = () => {
        const { id, question } = this.currentQuestion;
        if (this.seenQuestionsArray.indexOf(id) === -1) {
            if (question && [NO_ANSWER, FEEDBACK].includes(question.answerType)) {
                this.currentQuestion.questionHasAnswer = true;
                this.questions[this.currentQuestionIndex - 1].questionHasAnswer = true;
            }
            interviewApi.setQuestionAsSeen(id);
            this.seenQuestionsArray.push(id);
        }
    };

    @action setTimeSpentOnCurrentQuestion = () => {
        this.timeSpentOnCurrentQuestion = 1;

        this.statisticsTimerId = setInterval(() => {
            this.timeSpentOnCurrentQuestion += 1;
        }, 1000);
    };

    @action saveStatisticsTimerForPreviousQuestion = async (isSync = false) => {
        if (this.currentQuestionIndex && this.timeSpentOnCurrentQuestion) {
            if (isSync) {
                await interviewApi.setTimeSpentOnQuestion(this.currentQuestion.id, this.timeSpentOnCurrentQuestion);
            } else {
                interviewApi.setTimeSpentOnQuestion(this.currentQuestion.id, this.timeSpentOnCurrentQuestion);
            }
            clearTimeout(this.statisticsTimerId);
            this.timeSpentOnCurrentQuestion = 0;
        }
    };

    /* Timer for timed interview */
    @action setTimerForTimedInterview = (remainingTime) => {
        this.timerValue = remainingTime;
        if (!remainingTime) {
            this.isExpiredDialogOpened = true;
            return;
        }
        if (this.timerValue) {
            this.lastTimerTick = Date.now();
            this.timerInterviewId = setInterval(() => {
                const timePassed = Math.round((Date.now() - this.lastTimerTick) / 1000);
                this.timerValue = timePassed <= this.timerValue ? (this.timerValue - timePassed) : 0;
                this.lastTimerTick = Date.now();
                if (this.timerValue === 0) {
                    this.isExpiredDialogOpened = true;
                    this.saveStatisticsTimerForPreviousQuestion();
                    clearTimeout(this.timerInterviewId);
                }
            }, 1000);
        }
    };

    /* Timer for timed questions */
    @action startTimedQuestion = (questionId, index) => {
        this.questionReq = fromPromise(interviewApi.startTimedQuestion(questionId)
            .then((response) => {
                const { data } = response;
                const {
                    type, remainingTime, question, codeChallengeQuestionResults,
                    id, csSimulatorTicketsResults, linuxSimulationHostsCreated
                } = data;
                this.questions[index - 1].started = true;
                this.questions[index - 1].remainingTime = remainingTime;
                this.linuxSimulationHostsCreated = linuxSimulationHostsCreated;
                this.csSimulatorTicketsResults = csSimulatorTicketsResults;
                if (type === CODE_TEST_AUTOGRADED && question.codeChallengeQuestions && question.codeChallengeQuestions.length) {
                    this.codeChallengeResults = codeChallengeQuestionResults;
                    this.codeChallengeQuestions = question.codeChallengeQuestions;
                    this.initCodeChallenageParams(question.codeChallengeQuestions);
                    this.getTestCases(id);
                }
                this.setLinuxQuestionSeen(data);

                this.setStatisticsTimerAndSeenOnTimeOut();

                this.setTimerForTimedQuestion(index, data);
                return response;
            }));
    };

    @action setTimerForTimedQuestion = (index, { remainingTime, id, started, timedQuestion }) => {
        clearTimeout(this.timerId);
        const { timerEnabled: interviewTimerEnabled } = this.interview;
        if (interviewTimerEnabled) {
            return;
        }
        if (index !== this.currentQuestionIndex || !timedQuestion) {
            this.timerValue = null;
            return;
        }

        const currentQuestion = this.questions[index - 1];
        currentQuestion.started = started;
        currentQuestion.remainingTime = remainingTime;
        this.timerValue = remainingTime;

        if (!this.timerValue) return;
        this.lastTimerTick = Date.now();
        this.timerId = setInterval(async () => {
            const timePassed = Math.round((Date.now() - this.lastTimerTick) / 1000);
            this.timerValue = timePassed <= this.timerValue ? (this.timerValue - timePassed) : 0;
            this.lastTimerTick = Date.now();
            if (this.timerValue === 0) {
                currentQuestion.remainingTime = 0;
                clearInterval(this.timerId);
                if (index === this.currentQuestionIndex) {
                    this.isAnswerSaving = true;
                    this.saveStatisticsTimerForPreviousQuestion();
                    await when(() => !this.loadingItems.includes(id));
                    this.isAnswerSaving = false;
                }
            }
        }, 1000);
    };

    @action submitInterview = async (isAutoCompleteInterview) => {
        clearInterval(this.timerInterviewId);
        clearInterval(this.timerId);
        this.isAnswerSaving = true;
        this.submitURL = '';
        window.onkeydown = null;

        await this.saveStatisticsTimerForPreviousQuestion(true);
        await when(() => !this.loadingItems.length);
        this.isAnswerSaving = false;
        this.interview.completed = true;
        return interviewApi.submitInterview(this.interview.id, isAutoCompleteInterview)
            .then(({ success, data }) => {
                if (success) {
                    const { submitUrl } = this.interview;
                    const { ibmRedirectUrl } = data;
                    this.submitURL = submitUrl;
                    this.ibmRedirectUrl = ibmRedirectUrl;
                    if (this.interview.candidateFeedbackSeen && !this.interview.eeocEnabled) {
                        this.redirectAfterSubmit();
                    }
                }
            })
            .catch((error) => {
                this.interview.completed = false;
                if (error.response && error.response.status === 410) {
                    this.onInterviewExpired();
                    return { deadlineError: true };
                }
            });
    };

    @computed get incompletedQuestionsIndexes() {
        return this.questions.map((item, index) => {
            if (!item.questionHasAnswer) {
                return index + 1;
            }
            return null;
        }).filter((item) => {
            if (!item) return false;
            const questionItem = this.questions[item - 1];
            const noAnswerQuestions = [FEEDBACK, NO_ANSWER].includes(questionItem.type);
            const notTimedQuestion = !questionItem.timedQuestion;
            const timedQuestion = questionItem.timedQuestion && (questionItem.remainingTime || !questionItem.started);
            return (noAnswerQuestions || notTimedQuestion || timedQuestion);
        });
    }

    @computed get incompletedRequiredQuestionsIndexes() {
        return this.incompletedQuestionsIndexes.filter((item) => {
            const questionItem = this.questions[item - 1];
            return questionItem.question && questionItem.question.required;
        });
    }

    @action closeCancelUploadDialog = () => {
        this.cancelUploadDialogData = null;
    };

    @action setHasAnswerQuestion = (index = this.currentQuestionIndex) => {
        if (index === -1) return;
        this.questions[index - 1].questionHasAnswer = true;
    }

    @action openExternalFile = (id) => {
        this.questions[this.currentQuestionIndex - 1].questionHasAnswer = true;
        return interviewApi.openExternalFile(id);
    };

    @action redirectAfterSubmit = () => {
        const { onboarding } = this.interview;
        if (this.submitURL) {
            if (onboarding) {
                window.parent.location = this.submitURL;
                return;
            }
            window.location = this.submitURL;
        } else if (this.ibmRedirectUrl) {
            window.parent.location = this.ibmRedirectUrl;
        } else {
            routerStore.goTo({
                name: 'submitted_interview',
                params: { interviewId: this.interview.id }
            });
        }
    }

    @action completeLaterFunction = () => {
        this.interview.completed = true;
        if (this.interview.completeLaterUrl) {
            window.location = this.interview.completeLaterUrl;
        } else {
            routerStore.goTo({ name: 'index' });
        }
    };

    @action onWindowUnloadCallback = async (e) => {
        if (this.interview?.completed) return;
        e.preventDefault();
        await this.saveStatisticsTimerForPreviousQuestion();
        this.setStatisticsTimerAndSeenOnTimeOut();
        e.returnValue = '';
        return '';
    };


    @computed get assessmentLogo() {
        if (this.assessmentBranding && this.assessmentBranding.logoUrl.length) {
            return this.assessmentBranding.logoUrl[0];
        }
        return null;
    }
}

export const InterviewPageStore = new InterviewPage();
export const InterviewPageStoreCtx = createContext(InterviewPageStore);
