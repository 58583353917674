import React from 'react';
import { color } from 'src/utils/mui';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';


const useStyles = makeStyles({
    root: {
        color: color('textPrimary')
    }
});

export const CustomCircularProgress = ({ ...other }) => {
    const { root } = useStyles({});

    return (
        <CircularProgress
            size={30}
            classes={{ root }}
            thickness={3}
            {...other}
        />
    );
};
