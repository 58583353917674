import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import hexToRgba from 'hex-to-rgba';
import { useTheme } from '@material-ui/styles';
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery';

import defaultColors from './colors';

const breakpoints = createBreakpoints({});

export const colors = {
    textPrimary: '#3a3c43',
    textEmphasis: '#676a78',
    textSecondary: '#9fa0a5',
    textTertiary: '#1a1b1e',
    textQuaternary: '#606062',
    primary: '#1247F6',
    secondary: '#0A3EEA',
    success: '#69CD9D',
    disabled: '#EBECEE',
    neutral: '#ECEDEE',
    placeholder: '#9f9f9f',
    danger: '#f44336',
    borderPrimary: '#DDDEE2',
    globalBackground: '#FFFFFF',
    textSubtitle: '#3A465C',
    themeColor: '#65cbcb',
    logo: '#3f3f3f',
    tooltipColor: '#1f2032',
    recorderBorder: '#eeeeee'
};

export const buildVariant = (
    fontWeight,
    fontSize,
    colour
) => ({
    fontWeight,
    fontSize,
    color: colors[colour]
});

export const color = c => colors[c];
export const border = (width, colour, type = 'solid') => ({ border: `${width}px ${type} ${colors[colour]}` });

export const useWidth = (screen) => {
    const theme = useTheme();

    const width = ([...theme.breakpoints.keys].reverse().reduce(
        (output, key) => {
            const matches = useMediaQuery(theme.breakpoints.only(key));

            return !output && matches ? key : output;
        },
        null,
    ) || 'xs');

    return {
        width,
        isLess: !!screen && isWidthUp(width, screen),
        isMore: !!screen && isWidthDown(width, screen)
    };
};

export const themeConfig = color2 => ({
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        ...defaultColors,
        primary: {
            main: color2.themeColor
        },
        secondary: {
            main: defaultColors.blue[600]
        }
    },
    typography: {
        color: defaultColors.grey[900],
        fontFamily: [
            'Open Sans'
        ].join(','),
        h2: buildVariant(600, 24),
        h3: buildVariant(600, 16),
        h4: buildVariant(400, 20),
        h5: buildVariant(600, 14),
        subtitle1: buildVariant(400, 18, 'textPrimary'),
        subtitle2: buildVariant(600, 12, 'textSecondary'),
        caption: {
            ...buildVariant(400, 16, 'textEmphasis'),

            [breakpoints.down('xs')]: {
                ...buildVariant(400, 14, 'textEmphasis')
            }
        },
        useNextVariants: true
    },
    overrides: {
        /* here you can rewrite global mui component styles */
        MuiCard: {
            root: {
                border: `1px solid ${defaultColors.grey[300]}`,
                boxShadow: 'none',
                position: 'relative',
                maxWidth: 757,
                margin: '0 auto',
                borderRadius: 4,
                padding: 40,

                [breakpoints.down('xs')]: {
                    padding: 20
                }
            }
        },
        MuiCardContent: {
            root: {
                padding: 0,

                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: 20
            }
        },
        MuiCircularProgress: {
            root: {
                position: 'absolute',
                top: '40%',
                transition: 'translate(-50%)',
                display: 'block',
                margin: '0 auto'
            }
        },
        MuiAvatar: {
            root: {
                width: 43,
                height: 43
            }
        },
        MuiButton: {
            textPrimary: {
                color: color('themeColor'),
                '& $label': {
                    color: color('themeColor')
                }
            },
            textSecondary: {
                color: color('secondary'),
                '& $label': {
                    color: color('secondary')
                }
            },
            contained: {
                backgroundColor: color('globalBackground'),
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: '#EBEBEB'
                }
            },
            containedPrimary: {
                backgroundColor: `${hexToRgba(color('themeColor'), 0.9)}`,
                boxShadow: 'none',

                '&:hover': {
                    backgroundColor: color('themeColor')
                },

                '&$disabled': {
                    backgroundColor: color('themeColor')
                },

                '& $label': {
                    ...buildVariant(700, 14, 'globalBackground')
                }
            },
            containedSecondary: {
                boxShadow: 'none',
                '& $label': {
                    ...buildVariant(700, 14, 'globalBackground')
                },
                '&:hover': {
                    backgroundColor: color('secondary')
                },
                '&$disabled': {
                    backgroundColor: color('secondary')
                }
            },
            outlined: {
                borderWidth: 2
            },
            outlinedSecondary: {
                border: `2px solid ${colors.secondary}`,
                '& $label': {
                    ...buildVariant(700, 14, 'secondary')
                },
                '&:hover': {
                    border: `2px solid ${colors.secondary}`
                }
            },
            label: {
                ...buildVariant(700, 14, 'textTertiary')
            },
            root: {
                fontFamily: 'Open Sans',
                textTransform: 'none',
                minHeight: 40,
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 3,

                '&$disabled': {
                    opacity: 0.5
                }
            },
            text: {
                textTransform: 'none',
                minHeight: 40,
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 3
            }
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Open Sans',
                textTransform: 'none',

                '&$disabled': {
                    opacity: 0.3
                }
            }
        },
        MuiFormControl: {
            root: {
                width: 500,
                maxWidth: '100%',
                [breakpoints.down('xs')]: {
                    width: 335
                }
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItemText: {
            root: {
                fontFamily: 'Open Sans',
                ...buildVariant(500, 14, 'textEmphasis'),
                fontSize: 14,
                fontWeight: 500,
                padding: 0
            }
        },
        MuiCheckbox: {
            root: {
                color: `${color('themeColor')} !important`,

                '&$checked': {
                    color: `${color('themeColor')} !important`
                }
            },
            colorSecondary: {
                color: `${color('secondary')} !important`,

                '&$checked': {
                    color: `${color('secondary')} !important`
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                paddingTop: 12,
                width: 500,

                [breakpoints.down('xs')]: {
                    width: 335,
                    marginRight: 0
                }
            },
            label: {
                ...buildVariant(400, 14, 'textEmphasis')
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderRadius: 3,
                border: 'solid 2px #c7c7c7'
            },
            root: {
                backgroundColor: '#fff',
                '&:hover': {
                    borderColor: color('themeColor')
                }
            },
            multiline: {
                padding: 14,
                minHeight: 56
            }
        },
        MuiInputBase: {
            input: {
                ...buildVariant('normal', 14, 'textPrimary'),
                '&::placeholder': {
                    ...buildVariant(400, 14, 'placeholder')
                }
            }
        },
        MuiTypography: {
            root: {
                maxWidth: 500
            },
            h3: {
                lineHeight: 1.33
            }
        },
        MuiFormHelperText: {
            root: {
                maxWidth: 500
            }
        },
        MuiRadio: {
            colorSecondary: {
                color: color('themeColor'),
                '&$checked': {
                    color: color('themeColor')
                }
            }
        }
    }
});
