import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    flatButton: {
        marginRight: 16,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginLeft: 0,
            marginTop: 16
        }
    },
    dialogPaper: {
        margin: 20,
        minWidth: 600,
        maxWidth: 700,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto'
        }
    },
    submitting: {
        marginRight: 10,
        marginLeft: -10
    },
    circularProgress: {
        position: 'absolute',
        right: 13,
        top: 10,
        color: '#fff'
    },
    circularProgressSkip: {
        position: 'absolute',
        right: 4,
        top: 10,
        color: theme.palette.grey[800]
    },
    input: {
        marginTop: 20
    },
    inputRoot: {
        minHeight: 57
    },
    description: {
        textAlign: 'center'
    },
    select: {
        width: '100%',
        margin: '20px 0'
    },
    [theme.breakpoints.down('xs')]: {
        titleStyle: {
            textAlign: 'left!important',
            fontSize: 18
        },
        buttonStyle: {
            width: '100%'
        },
        description: {
            textAlign: 'left',
            fontSize: 10,
            marginBottom: 10
        },
        select: {
            width: '100%',
            margin: '10px 0'
        }
    }
}));
