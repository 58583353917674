import React, { useContext, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { ScreeningContentStoreCtx } from 'src/pages/interview/screeningStore';
import { formMobileExpirationStatus } from 'src/utils/helperFunctions';
import { StepperItem } from 'src/components/stepper_item';
import { color } from 'src/utils/mui';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '../submit_button';

import { useStyles } from './styles';
import { TimerLabel } from '../../../../../timer_label';

export const MobileHeader = observer(({ handleSubmitDialog, handleCompleteLaterDialog }) => {
    const [opened, handleOpen] = useState(false);
    const {
        interview, questions, incompletedQuestionsIndexes,
        changeQuestion, timerValue, currentQuestion
    } = useContext(InterviewPageStoreCtx);
    const {
        currentQuestion: currentScreeningQuestion,
        countAnswered, currentQuestionIndex,
        errorOnStart
    } = useContext(ScreeningContentStoreCtx);

    const {
        menuIcon, paperDrawer,
        drawerHeader, completedLaterLabel,
        completedLaterButton, backIcon,
        backIconButton, menuWrapper,
        statusWrapper, ttInfoWrapper,
        statusWrapperThemeEmphasis,
        headerLeftWrapper, stepsWrapper,
        headerWrapper, counter
    } = useStyles({});
    const { i18n, t } = useTranslation();
    const isES = i18n.language === 'es';

    if (!interview?.questionCount || !(currentQuestion || currentScreeningQuestion) || errorOnStart) return null;

    const answeredCount = currentQuestion ? (questions.length - incompletedQuestionsIndexes.length + interview?.countScreeningQuestions) : countAnswered;
    const totalCount = interview?.questionCount + interview?.countScreeningQuestions;

    const {
        expirationTimeStatus, expirationDate, timerEnabled,
        timerCount, hideCompleteLaterButton, countScreeningQuestions,
        screeningStatus
    } = interview;

    const expirationDateLabel = moment(expirationDate).format(isES ? '[el] D [de] MMMM [de] YYYY' : 'MMMM Do YYYY');
    const headerStatus = (
        <div className={statusWrapper}>
            <div className={counter}>
                <span className={statusWrapperThemeEmphasis}>{answeredCount}</span> / {totalCount}
            </div>&middot;
            {(timerEnabled && !screeningStatus)
                ? (
                    <TimerLabel
                        xs
                        value={timerValue}
                        totalValue={timerCount * 60}
                    />
                )
                : <span style={{ paddingLeft: 15 }}>{formMobileExpirationStatus(expirationTimeStatus, i18n.language)}</span> }
        </div>
    );

    return (
        <>
            <div className={headerWrapper}>
                <div className={headerLeftWrapper}>
                    <IconButton className={menuIcon} onClick={() => handleOpen(true)} color="inherit" aria-label="Open drawer">
                        <MenuIcon />
                    </IconButton>
                    {headerStatus}
                </div>
                <SubmitButton
                    handleSubmitDialog={handleSubmitDialog}
                    isAllCompleted={!incompletedQuestionsIndexes.length}
                />
            </div>
            <SwipeableDrawer
                open={opened}
                onClose={() => handleOpen(false)}
                onOpen={() => handleOpen(true)}
                classes={{ paper: paperDrawer }}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => handleOpen(false)}
                    onClick={() => handleOpen(false)}
                >
                    <div className={drawerHeader}>
                        <div>
                            <IconButton className={backIconButton} onClick={() => handleOpen(false)} color="inherit" aria-label="Open drawer">
                                <ArrowBack className={backIcon} />
                            </IconButton>
                        </div>
                        { (!hideCompleteLaterButton && screeningStatus !== 'screeningFailed') && (
                            <Button
                                onClick={handleCompleteLaterDialog}
                                classes={{
                                    root: completedLaterButton,
                                    label: completedLaterLabel
                                }}
                            >
                                <span style={{ color: color('themeColor') }}>
                                    {t('Complete Later')}
                                </span>
                            </Button>
                        )}
                    </div>
                    <div className={menuWrapper}>
                        <div className={ttInfoWrapper}>
                            {headerStatus}
                            <div>
                                {t('Assessment expires on {{expirationDateLabel}}', { expirationDateLabel })}
                            </div>
                        </div>
                        <div className={stepsWrapper}>
                            <div>
                                {
                                    [...Array(countScreeningQuestions + (currentScreeningQuestion ? interview.questionCount : 0))]
                                        .map((item, index) => (
                                            <StepperItem
                                                index={index + 1}
                                                key={index}
                                                item={{
                                                    questionHasAnswer: Boolean(index < currentQuestionIndex || questions?.length),
                                                    questionDescription: ' '
                                                }}
                                                disabled={!item}
                                                active={Boolean(currentQuestionIndex - 1 === index && currentScreeningQuestion)}
                                                changeQuestion={changeQuestion}
                                                opened={opened}
                                            />
                                        ))
                                }
                                {
                                    questions?.map((item, index) => (
                                        <StepperItem
                                            index={countScreeningQuestions + index + 1}
                                            key={item.id}
                                            opened={opened}
                                            item={item}
                                            changeQuestion={changeQuestion}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    );
});
