import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { InterviewPageStoreCtx } from '../../../../../../pages/interview/store';
import { ScreeningContentStoreCtx } from '../../../../../../pages/interview/screeningStore';
import { InterviewStatus } from '../../../../../interview_status';
import { SubmitButton } from '../submit_button';

import { useStyles } from './styles';

export const DesktopHeader = observer(({ handleCompleteLaterDialog, handleSubmitDialog }) => {
    const { t } = useTranslation();
    const { wrapper, buttonsWrapper } = useStyles({});
    const { interview, timerValue, questions, incompletedQuestionsIndexes } = useContext(InterviewPageStoreCtx);
    const { screeningStatus, currentQuestion } = useContext(ScreeningContentStoreCtx);

    if (!interview || (!questions?.length && !currentQuestion)) return null;

    const { hideCompleteLaterButton } = interview;

    return (
        <div className={wrapper}>
            <InterviewStatus
                interview={interview}
                timerValue={timerValue}
            />
            <div className={buttonsWrapper}>
                { (!hideCompleteLaterButton && screeningStatus !== 'screeningFailed') && (
                    <Button onClick={handleCompleteLaterDialog}>
                        {t('Complete later')}
                    </Button>
                )}
                <SubmitButton
                    isAllCompleted={!incompletedQuestionsIndexes.length}
                    handleSubmitDialog={handleSubmitDialog}
                />
            </div>
        </div>
    );
});
