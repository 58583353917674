import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export const CenteredContent = ({ children, className }) => {
    const { center } = useStyles({});
    return (
        <div className={`${center} ${className}`}>{children}</div>
    );
};
