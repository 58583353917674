import { createContext } from 'react';
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';

import { dashbooardApi } from 'src/api/dashboard';
import { routerStore } from 'src/store/router';

class HomePage {
    @observable.ref req = fromPromise.resolve([]);

    @action fetchOpportunities = async () => {
        const res = await dashbooardApi.getOpportunities();
        if (res && res.success) {
            const promiseArray = res.data.map(
                async ({ slug }, i) => Promise.resolve(
                    await dashbooardApi.userAuditions(slug).then(
                        ({ data }) => {
                            return {
                                auditions: data,
                                ...res.data[i]
                            };
                        }
                    )
                )
            );

            this.req = fromPromise(Promise.all(promiseArray));
        }
    };

    @action moreTime = (auditionId, reasonType, reason) => {
        return dashbooardApi.moreTime(auditionId, reasonType, reason);
    };

    @action cleanData = () => {
        this.req = fromPromise.resolve([]);
    }
}
export const homePageStore = new HomePage();
export const homePageStoreCtx = createContext(homePageStore);
