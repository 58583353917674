import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    flatButton: {
        marginRight: 16,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginTop: 16
        }
    },
    dialogPaper: {
        margin: 20,
        minWidth: 600,
        maxWidth: 700,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto'
        }
    },
    submitting: {
        marginRight: 10,
        marginLeft: -10
    },
    circularProgress: {
        position: 'absolute',
        right: 13,
        top: 10,
        color: '#fff'
    },
    circularProgressSkip: {
        position: 'absolute',
        right: 4,
        top: 10,
        color: theme.palette.grey[800]
    },
    [theme.breakpoints.down('xs')]: {
        titleStyle: {
            textAlign: 'center'
        },
        contentStyle: {
            textAlign: 'center'
        },
        buttonStyle: {
            width: '100%'
        }
    },
    gradeWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    grade: {
        cursor: 'pointer',
        borderRadius: '50%',
        width: 40,
        height: 40,
        marginRight: 22,
        fontSize: 14,
        fontWeight: 700,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        color: theme.palette.grey[800],
        border: '2px solid #fff',
        transition: 'all 0.3s',
        '&:not($gradeSelected):hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        '&:nth-child(6)': {
            [theme.breakpoints.down('xs')]: {
                marginRight: 0
            }
        }
    },
    gradeRate: {
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.grey[900],
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: 19,
            right: 15,
            textAlign: 'right'
        },
        '&:first-child': {
            textAlign: 'right',
            marginRight: 22,
            [theme.breakpoints.down('xs')]: {
                right: 'auto',
                left: 15,
                textAlign: 'left'
            }
        }
    },
    gradeSelected: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderColor: theme.palette.primary.main
    },
    gradeDisabled: {
        cursor: 'default',
        '&:hover': {
            color: `${theme.palette.grey[800]}!important`,
            borderColor: '#fff!important'
        }
    },
    wrapper: {
        borderRadius: 8,
        margin: '20px 0 30px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 16px 12px',
        userSelect: 'none',
        transition: 'all 0.3s',
        backgroundColor: theme.palette.grey[200],
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            margin: '20px 0',
            position: 'relative'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 70
        }
    },
    reasonInput: {
        marginTop: 20
    },
    reasonInputInput: {
        minHeight: 57
    }
}));
