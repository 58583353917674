import React from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';

import { useStyles } from './styles';

const Item = ({ primary, src, textStyles, onClick }) => {
    const {
        listItemText,
        listItemIcon,
        listItem
    } = useStyles({});
    return (
        <ListItem
            onClick={onClick}
            classes={{ root: listItem }}
            button
        >
            {
                src && (
                    <ListItemIcon classes={{ root: listItemIcon }}>
                        <img {...{ src }} alt=""/>
                    </ListItemIcon>
                )
            }
            <ListItemText
                classes={{ primary: textStyles || listItemText }}
                primary={primary}
            />
        </ListItem>
    );
};

export default Item;
