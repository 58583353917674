import makeStyles from '@material-ui/styles/makeStyles';
import { buildVariant } from '../../utils/mui';

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`
            }
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`
            }
        }
    };
}

export const useStyles = makeStyles((theme) => {
    const tooltip = {
        boxShadow: theme.shadows[1],
        backgroundColor: '#1f2032',
        fontSize: 11,
        opacity: 1,
        fontWeight: 600,
        padding: '8px 20px',
        ...buildVariant(600, 11, 'globalBackground')
    };
    return {
        arrowPopper: arrowGenerator('#1f2032'),
        arrowPopperLight: arrowGenerator('#fff'),
        arrow: {
            position: 'absolute',
            fontSize: 6,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid'
            }
        },
        tooltip,
        tooltipLight: {
            ...tooltip,
            boxShadow: theme.shadows[5],
            backgroundColor: '#fff',
            color: '#3a3c43'
        }
    };
});
